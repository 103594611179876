<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-additional-facility-modal @click="$bvModal.show('add-update-additional-facility-modal'), resetForm()">
          <i class="fa fa-plus"/> Add Additional Facility
        </a>
        <table class="table table-sm table-bordered" id="additional_facility_list">
          <thead>
          <tr>
            <th style="width: 5%">SL</th>
            <th>Name</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(additional_facility, index) in additional_facilities" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ additional_facility.name }}</td>
            <td>{{ additional_facility.description }}</td>
            <td>
              <a class="btn btn-sm btn-primary mr-1" href="javascript: void(0);" @click.prevent="edit(additional_facility.id), $bvModal.show('add-update-additional-facility-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
              <a class="btn btn-sm btn-danger mr-1" href="javascript: void(0);" @click.prevent="onDelete(additional_facility.id)"><i class="fe fe-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="additional_facilities.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-additional-facility
      :additional_facility="additional_facility"
      :btnEdit="btnEdit"
      @getAdditionalFacilities="getAdditionalFacilities"
      @resetForm="resetForm"
      ref="addUpdateAdditionalFacilities">
    </add-update-additional-facility>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateAdditionalFacility from '@/views/roomBooking/admin/additionalFacility/modal/addUpdateAdditionalFacility'

export default {
  name: 'List',
  components: { Empty, addUpdateAdditionalFacility },
  data() {
    return {
      additional_facilities: [],
      additional_facility: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getAdditionalFacilities()
  },
  methods: {
    getAdditionalFacilities() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/additional-facilities')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.additional_facilities = response.data.additional_facilities
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.additional_facility = {}
      this.btnEdit = true
      apiClient.get('api/additional-facilities/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.additional_facility = response.data.additional_facility
        }
      })
    },
    onDelete(additionalFacilityId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/additional-facilities/' + additionalFacilityId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getAdditionalFacilities()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.additional_facility = {}
    },
  },
}
</script>

<style scoped>
  table {
    table-layout:fixed;
  }
  td {
    word-wrap:break-word;
  }
  .item:last-child .comma {
    display: none;
  }
</style>
